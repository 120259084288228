
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { AxAttachementUrl } from '../models/ax-attachement-url';



export function getSendMailFromTemplateDtoFormGroup() {
return new FormGroup({
    onlyCreateDraft: new FormControl<any | undefined>(undefined, [Validators.required]),
    senderUserId: new FormControl<number | undefined>(undefined),
    targetContactId: new FormControl<number | undefined>(undefined),
    targetUserId: new FormControl<number | undefined>(undefined),
    targetEmail: new FormControl<string | undefined>(''),
    template: new FormControl<any>(undefined),
    attachementUrls: new FormControl<AxAttachementUrl[]>([]),
    secret: new FormControl<string | undefined>(''),
})
;
}
      