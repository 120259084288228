import { Component, Input, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngxs/store';
import {
  AxCalculationitem,
  AxElectricalMeasurement,
  AxElectricalMeasurementCable,
  AxElectricalMeasurementsService,
  AxFiDeltaNEnum,
  AxLsCharakteristikEnum,
  AxProjectsService,
  AxUpdateElectricalMeasurementDto,
} from 'src/app/_generated/axova-rest-api';
import { AppState } from 'src/app/ngxs/app/app.state';
import { RolesEnum } from 'src/app/ngxs/app/app.state.model';
import {
  CreateElectricalMeasurementAddMeasurement,
  CreateElectricalMeasurementDeleteMeasurementAtIndex,
  CreateElectricalMeasurementUpdateMeasurementAtIndex,
} from 'src/app/ngxs/create-electrical-measurement/create-electrical-measurement.actions';
import { CreateElectricalMeasurementState } from 'src/app/ngxs/create-electrical-measurement/create-electrical-measurement.state';
import * as moment from 'moment';
import { AxUiService } from '../../../../services/ax-ui.service';

@Component({
  selector: 'app-create-electrical-measurement-detail',
  templateUrl: './create-electrical-measurement-detail.page.html',
  styleUrls: ['./create-electrical-measurement-detail.page.scss'],
})
export class CreateElectricalMeasurementDetailPage implements OnInit {
  @Input('indexOfMeasurement') indexOfMeasurement = -1;
  @Input('electricalMeasurement') electricalMeasurement: Partial<AxElectricalMeasurement> = {};
  @Input('readonly') readonly = false;
  public baseMeasurement$ = this.store.select(CreateElectricalMeasurementState.baseMeasurement);
  public roles$ = this.store.select(AppState.roles);
  public electricalMeasurementCablesAc: AxElectricalMeasurementCable[] = [];
  public electricalMeasurementCablesDc: AxElectricalMeasurementCable[] = [];
  public modules: Array<AxCalculationitem> = [];
  public lsCharakteristikEnumList = Object.values(AxLsCharakteristikEnum);
  public fiDeltaEnumList = Object.values(AxFiDeltaNEnum);
  public isMonteurOnly = false;

  constructor(
    private title: Title,
    private store: Store,
    private modalController: ModalController,
    private axElectricalMeasurementsService: AxElectricalMeasurementsService,
    private axProjectsService: AxProjectsService,
    private axUiService: AxUiService,
  ) {
  }

  ngOnInit() {
    this.initializeCables();
    const baseMeasurement = this.store.selectSnapshot(CreateElectricalMeasurementState.baseMeasurement);
    if (baseMeasurement.electricalMeasurementTypeId !== 1 || this.electricalMeasurement.electricalMeasurementTypeId !== 1) {
      this.initializeModules();
    }

    // Filter monteur-only role.
    this.roles$.subscribe(roles => {
      this.isMonteurOnly = roles.indexOf(RolesEnum.monteur) > -1 && roles.indexOf(RolesEnum.elektriker) === -1;
    });

    // Set readonly status based on time (allow editing until 24h after creation).
    if (this.readonly && this.electricalMeasurement) {
      if (moment(this.electricalMeasurement.created).isAfter(moment().subtract(24, 'hours'))) {
        this.readonly = false;
      }
    }
  }

  ionViewDidEnter() {
    this.title.setTitle(`Elektrische Messungen`);
  }

  async update() {
    await this.axUiService.showLoading();
    this.axElectricalMeasurementsService.electricalMeasurementsControllerUpdate({
      id: this.electricalMeasurement.id,
      body: this.electricalMeasurement as AxUpdateElectricalMeasurementDto,
    }).subscribe({
      next: async updatedMeasurement => {
        this.axUiService.showSuccess('Änderungen wurden übermittelt').then();
        await this.axUiService.hideLoading();
        await this.close();
      },
      error: async measurementNotUpdatedException => {
        this.axUiService.showError('Anpassungen konnten nicht übermittelt werden.').then();
        await this.axUiService.hideLoading();
      },
    });
  }

  async close(save: boolean = false) {
    if (save) {
      if (this.indexOfMeasurement === -1) {
        await this.store.dispatch(new CreateElectricalMeasurementAddMeasurement(this.electricalMeasurement)).toPromise();
      } else {
        await this.store.dispatch(new CreateElectricalMeasurementUpdateMeasurementAtIndex(this.electricalMeasurement, this.indexOfMeasurement)).toPromise();
      }
    }
    await this.modalController.dismiss(this.electricalMeasurement);
  }

  async delete() {
    if (this.indexOfMeasurement !== -1) {
      await this.store.dispatch(new CreateElectricalMeasurementDeleteMeasurementAtIndex(this.indexOfMeasurement)).toPromise();
      this.modalController.dismiss(this.electricalMeasurement).then();
    }
    if (this.electricalMeasurement.id) {
      await this.axUiService.showLoading();
      try {
        const updatedElectricalMeasurement = await this.axElectricalMeasurementsService.electricalMeasurementsControllerDelete({ id: this.electricalMeasurement.id }).toPromise();
        this.axUiService.showSuccess('Messung gelöscht').then();
        await this.axUiService.hideLoading();
        this.modalController.dismiss(updatedElectricalMeasurement).then();
      } catch (electricalMeasurementNotDeleted) {
        this.axUiService.showError('Messung konnte nicht gelöscht werden.').then();
        await this.axUiService.hideLoading();
      }
    }
  }

  getModuleByItemId(itemId) {
    return this.modules.find(module => module.id = itemId);
  }

  private initializeCables() {
    this.axElectricalMeasurementsService.electricalMeasurementsControllerGetCables()
      .subscribe({
        next: cables => {
          this.electricalMeasurementCablesAc = cables.filter(cable => cable.name.indexOf('FE0') > -1);
          this.electricalMeasurementCablesDc = cables.filter(cable => cable.name.indexOf('FE0') === -1);
        },
      });
  }

  private initializeModules() {
    let projectId;
    try {
      projectId = this.store.selectSnapshot(CreateElectricalMeasurementState.project).id;
    } catch (noProject) {
      projectId = this.electricalMeasurement.projectId;
    }
    this.axProjectsService.projectsControllerGetItemsForProject({ id: projectId }).subscribe({
      next: result => {
        const modules = result.modules;
        if (modules && modules.length === 1) {
          this.electricalMeasurement.dcItemId = modules[0].item.id;
          this.electricalMeasurement.dcItem = modules[0].item;
        }
        this.modules = result.modules;
      },
    });
  }
}
