import { Injectable, NgZone } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { ToastController } from '@ionic/angular';
import { AxovaQrCode } from 'src/app/models/axova-qr-code.class';
import { PermissionsService } from 'src/app/services/permissions.service';
import { CapacitorBarcodeScanner } from '@capacitor/barcode-scanner';
import { CapacitorBarcodeScannerTypeHint } from '@capacitor/barcode-scanner/dist/esm/definitions';

@Injectable({
  providedIn: 'root',
})
export class QrcodeService {
  constructor(
    private toastController: ToastController,
    private permissionsService: PermissionsService,
    private ngZone: NgZone,
  ) {
  }

  /**
   * Start the QR/App Access Code Scan.
   */
  public async startScanner(mockdataType?: 'project' | 'equipment'): Promise<AxovaQrCode | undefined> {
    return new Promise(async (resolve) => {
      if (!Capacitor.isNativePlatform()) {
        setTimeout(() => {
          if (mockdataType && mockdataType === 'project') {
            resolve(new AxovaQrCode('{"type":"project","data":{"id":"7986"}}'));
          } else if (mockdataType && mockdataType === 'equipment') {
            resolve(new AxovaQrCode('{"type":"equipment","data":{"id":"38"}}'));
          }
        }, 100);
      } else {
        const hasPermissions = await this.permissionsService.checkCameraPermissions('camera');
        if (hasPermissions) {
          const result = await CapacitorBarcodeScanner.scanBarcode({ hint: CapacitorBarcodeScannerTypeHint.QR_CODE });
          this.ngZone.run(() => {
            const qrCode = new AxovaQrCode(result.ScanResult);
            if (qrCode.isValid) {
              resolve(qrCode);
            } else {
              this.showInvalidQrCodeMessage().then();
              resolve(undefined);
            }
          });
        } else {
          resolve(undefined);
        }
      }
    });
  }

  private async showInvalidQrCodeMessage() {
    const toast = await this.toastController.create({
      message: 'Dieser QR-Code ist ungültig.',
      buttons: [
        {
          text: 'OK',
          role: 'cancel',
        },
      ],
    });
    await toast.present();
  }
}
