
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { AxMessagingPhone } from '../models/ax-messaging-phone';

import { AxMessagingMail } from '../models/ax-messaging-mail';

import { AxPushNotificationToken } from '../models/ax-push-notification-token';

import { requiredBoolean } from './required-boolean.validator';

export function getSendMessageDtoFormGroup() {
return new FormGroup({
    user: new FormControl<any>(undefined),
    contact: new FormControl<any>(undefined),
    phones: new FormControl<AxMessagingPhone[]>([]),
    mails: new FormControl<AxMessagingMail[]>([]),
    pushNotificationTokens: new FormControl<AxPushNotificationToken[]>([]),
    sendFromNeutralAccount: new FormControl<boolean | undefined>(false, [requiredBoolean()]),
    messageText: new FormControl<string | undefined>('', [Validators.required]),
    userId: new FormControl<number | undefined>(undefined),
    contactId: new FormControl<number | undefined>(undefined),
    sendPushnotification: new FormControl<boolean | undefined>(false, [requiredBoolean()]),
})
;
}
      