
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';


export function getSendMailTemplateMetaFormGroup() {
return new FormGroup({
    isSolaredge: new FormControl<boolean | undefined>(undefined),
    warEwElektrikerDate: new FormControl<number | undefined>(undefined),
    warNote: new FormControl<string | undefined>(''),
    invoiceReminderLevel: new FormControl<number | undefined>(undefined),
    invoiceNumber: new FormControl<string | undefined>(''),
    invoiceDate: new FormControl<string | undefined>(''),
    terminassistentLink: new FormControl<string | undefined>(''),
    projectIds: new FormControl<Array<number> | undefined>([]),
    includeSpeicheruebersicht: new FormControl<boolean | undefined>(undefined),
    includeDatenblaetter: new FormControl<boolean | undefined>(undefined),
})
;
}
      