import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { doc, docData, Firestore } from '@angular/fire/firestore';
import { NavigationEnd, Router } from '@angular/router';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { IonRouterOutlet, ModalController, NavController, Platform } from '@ionic/angular';
import { Actions, ofActionCompleted, Store } from '@ngxs/store';
import { LoggerService } from 'src/app/ax-commons-logger/logger/logger.service';
import { AppStateSetProfile } from 'src/app/ngxs/app/app.actions';
import { AppState } from 'src/app/ngxs/app/app.state';
import { RolesEnum, RolesEnumGroups } from 'src/app/ngxs/app/app.state.model';
import { InfoModalPage } from 'src/app/pages/global/info-modal/info-modal.page';
import { AppDeviceService } from 'src/app/services/app-device.service';
import { AppUpdateService } from 'src/app/services/app-update.service';
import { PushNotificationsService } from 'src/app/services/push-notifications.service';
import { environment } from 'src/environments/environment';
import { DarkmodeService } from './services/darkmode.service';
import { PushNotifications } from '@capacitor/push-notifications';
import { ActionPerformed } from '@capacitor/push-notifications/dist/esm/definitions';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  @ViewChild(IonRouterOutlet, { static: true }) routerOutlet: IonRouterOutlet;
  public maintenanceModal;
  private currentRouteUrl = '';

  constructor(
    private darkmodeService: DarkmodeService,
    private router: Router,
    private pushNotificationsService: PushNotificationsService,
    private actions: Actions,
    private firestore: Firestore,
    private modalController: ModalController,
    private platform: Platform,
    private navController: NavController,
    private zone: NgZone,
    private store: Store,
    private appDeviceService: AppDeviceService,
    private appUpdateService: AppUpdateService,
  ) {
  }

  public ngOnInit() {
    this.darkmodeService.initializeDarkMode().then();
    this.listenForRouterEvents();
    this.initializeAnalytics();
    this.listenForLoginEvent();
    this.listenForMaintenanceMode();
    this.listenForAndroidBackButton();
    this.listenForUniversalLinks();
    this.listenForAppUpdates();
    this.listenForPushNotificationActions();
  }

  listenForMaintenanceMode() {
    const document = doc(this.firestore, 'axova-app-options/y11uyse7fqyQgfA1jmQh');
    docData(document).subscribe(async data => {
      LoggerService.LOG(this, 'firebaseData', data);
      try {
        if (data && data?.value === true) {
          if (this.maintenanceModal) {
            this.maintenanceModal.dismiss();
          }
          this.maintenanceModal = await this.modalController.create({
            component: InfoModalPage,
            componentProps: {
              infoModalData: {
                title: data?.title,
                subtitle: data?.subtitle,
                message: data?.text,
              },
            },
          });
          await this.maintenanceModal.present();
        } else {
          this.maintenanceModal.dismiss();
          this.maintenanceModal = null;
        }
      } catch (firebaseException) {
        try {
          this.maintenanceModal.dismiss();
          this.maintenanceModal = null;
        } catch (modalException) {
          // Nothing to do.
        }
        // Nothing to do.
      }
    });
  }

  /**
   * Triggered when app comes to foreground. Checking for updates.
   *
   * @private
   */
  private listenForAppUpdates() {
    App.addListener('appStateChange', ({ isActive }) => {
      if (isActive) {
        this.appUpdateService.checkForAppUpdate().then();
      }
    });
  }

  /**
   * Listen for login event to register push notification
   * tokens (incl. permission request etc.)
   *
   * @private
   */
  private listenForLoginEvent() {
    this.actions
      .pipe(
        ofActionCompleted(AppStateSetProfile),
      )
      .subscribe(() => {
        // Initial registration of device.
        this.appDeviceService.initializeAppDeviceInformation().then();

        const roles = this.store.selectSnapshot(AppState.roles);
        const isInternalEmployee = () =>
          roles.some((role: RolesEnum) =>
            RolesEnumGroups.alle_internen_mitarbeitenden.includes(role),
          );
        if (isInternalEmployee()) {
          this.pushNotificationsService.register(true).then();
        } else {
          this.pushNotificationsService.register().then();
        }
      });
  }

  /**
   * Enable/disable swipe gesture based on route.
   *
   * @private
   */
  private listenForRouterEvents() {
    this.router.events.subscribe({
      next: event => {
        if (event instanceof NavigationEnd) {
          this.currentRouteUrl = event.url;
          this.routerOutlet.swipeGesture = !(event.url && event.url.indexOf('tabs') > -1);
          if (event.url && event.url.indexOf('home') > -1) {
            SplashScreen.hide().then();
          }
        }
      },
    });
  }

  private listenForAndroidBackButton() {
    this.platform.backButton.subscribeWithPriority(10, () => {
      const shouldGoBack = this.currentRouteUrl.indexOf('home') === -1;
      if (shouldGoBack) {
        if (this.currentRouteUrl === '/') {
          try {
            App.exitApp();
          } catch (cannotExitAppException) {
            LoggerService.LOG(this, 'cannotExitAppException', cannotExitAppException);
          }
        } else {
          this.navController.back();
        }
      } else {
        try {
          App.exitApp();
        } catch (cannotExitAppException) {
          LoggerService.LOG(this, 'cannotExitAppException', cannotExitAppException);
        }
      }
    });
  }

  /**
   * Initialize Firebase analytics.
   *
   * @private
   */
  private initializeAnalytics() {
    if (!Capacitor.isNativePlatform()) {
      FirebaseAnalytics.initializeFirebase(environment.firebase.configuration)
        .then(initialized => {
          LoggerService.LOG(this, 'Firebase Analytics initialized', initialized);
          FirebaseAnalytics.setUserProperty({
            name: 'environment',
            value: environment.name,
          }).then();
        })
        .catch(firebaseInitializeException => {
          LoggerService.ERROR(this, 'firebaseInitializeException', firebaseInitializeException);
        });
    } else {
      FirebaseAnalytics.setUserProperty({
        name: 'environment',
        value: environment.name,
      }).then();
    }
  }

  /**
   * Listen for universal links to open in app.
   *
   * @private
   */
  private listenForUniversalLinks() {
    App.addListener('appUrlOpen', (urlOpenListenerEvent: URLOpenListenerEvent) => {
      try {
        this.zone.run(() => {
          const isValidHostnameIndex = urlOpenListenerEvent.url.indexOf(environment.axovaOffice.baseUrl);
          if (isValidHostnameIndex > -1) {
            const path = urlOpenListenerEvent.url.substring(isValidHostnameIndex + environment.axovaOffice.baseUrl.length);
            LoggerService.LOG(this, 'path', path);
            const controller = path.split('/')[1];
            const action = path.split('/')[2];
            const id = path.split('/')[3];
            switch (controller) {
              case 'contacts':
                this.router.navigateByUrl(`/global/contact-details/${id}`).then();
                break;
              case 'projects':
                this.router.navigateByUrl(`/global/project-detail/${id}`).then();
                break;
              case 'users':
                this.router.navigateByUrl(`/global/user/${id}`).then();
                break;
            }
          }
        });
      } catch (universalLinkException) {
        LoggerService.LOG(this, 'universalLinkException', universalLinkException);
      }
    });
  }

  private listenForPushNotificationActions() {
    PushNotifications.addListener('pushNotificationActionPerformed',
      (res: ActionPerformed) => {
        this.zone.run(() => {
          if (res.notification.data) {
            try {
              if (res.notification.data.relatedEntityId !== 'undefined') {
                switch (res.notification.data.relatedEntityType) {
                  case 'event':
                    this.router.navigateByUrl(`/global/event-detail/${res.notification.data.relatedEntityId}`).then();
                    break;
                  case 'news':
                    this.router.navigateByUrl(`/global/news-detail/${res.notification.data.relatedEntityId}`).then();
                    break;
                  case 'absence':
                    this.router.navigateByUrl(`/employees-tabs/more/absences/update/${res.notification.data.relatedEntityId}`).then();
                    break;
                }
              }
            } catch (readNotificationDataException) {
              LoggerService.ERROR(this, 'readNotificationDataException', readNotificationDataException);
            }
          }
        });
      });
  }
}
