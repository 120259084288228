/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AxMessagingOptionsDto } from '../models/ax-messaging-options-dto';
import { AxSendMessageDto } from '../models/ax-send-message-dto';
import { AxSuccessDto } from '../models/ax-success-dto';

@Injectable({ providedIn: 'root' })
export class AxMessagingV2Service extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `messagingControllerGetMessagingOptionsForUser()` */
  static readonly MessagingControllerGetMessagingOptionsForUserPath = '/v2/messaging/options/user/{userId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagingControllerGetMessagingOptionsForUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagingControllerGetMessagingOptionsForUser$Response(
    params: {
      userId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxMessagingOptionsDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxMessagingV2Service.MessagingControllerGetMessagingOptionsForUserPath, 'get');
    if (params) {
      rb.path('userId', params.userId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxMessagingOptionsDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagingControllerGetMessagingOptionsForUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagingControllerGetMessagingOptionsForUser(
    params: {
      userId: number;
    },
    context?: HttpContext
  ): Observable<AxMessagingOptionsDto> {
    return this.messagingControllerGetMessagingOptionsForUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxMessagingOptionsDto>): AxMessagingOptionsDto => r.body)
    );
  }

  /** Path part for operation `messagingControllerGetMessagingOptionsForContact()` */
  static readonly MessagingControllerGetMessagingOptionsForContactPath = '/v2/messaging/options/contact/{contactId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagingControllerGetMessagingOptionsForContact()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagingControllerGetMessagingOptionsForContact$Response(
    params: {
      contactId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxMessagingOptionsDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxMessagingV2Service.MessagingControllerGetMessagingOptionsForContactPath, 'get');
    if (params) {
      rb.path('contactId', params.contactId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxMessagingOptionsDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagingControllerGetMessagingOptionsForContact$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagingControllerGetMessagingOptionsForContact(
    params: {
      contactId: number;
    },
    context?: HttpContext
  ): Observable<AxMessagingOptionsDto> {
    return this.messagingControllerGetMessagingOptionsForContact$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxMessagingOptionsDto>): AxMessagingOptionsDto => r.body)
    );
  }

  /** Path part for operation `messagingControllerSendMessage()` */
  static readonly MessagingControllerSendMessagePath = '/v2/messaging';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagingControllerSendMessage()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  messagingControllerSendMessage$Response(
    params: {
      body: AxSendMessageDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxSuccessDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxMessagingV2Service.MessagingControllerSendMessagePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxSuccessDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagingControllerSendMessage$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  messagingControllerSendMessage(
    params: {
      body: AxSendMessageDto
    },
    context?: HttpContext
  ): Observable<AxSuccessDto> {
    return this.messagingControllerSendMessage$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxSuccessDto>): AxSuccessDto => r.body)
    );
  }

}
