/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AxCreatedReferralCodeDto } from '../models/ax-created-referral-code-dto';
import { AxReferral } from '../models/ax-referral';
import { AxReferralSummaryDto } from '../models/ax-referral-summary-dto';

@Injectable({ providedIn: 'root' })
export class AxReferralsV2Service extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `referralsControllerGetCreatedReferralsByContact()` */
  static readonly ReferralsControllerGetCreatedReferralsByContactPath = '/v2/referrals/createdReferralsByContact/{contactId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `referralsControllerGetCreatedReferralsByContact()` instead.
   *
   * This method doesn't expect any request body.
   */
  referralsControllerGetCreatedReferralsByContact$Response(
    params: {
      contactId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxReferral>>> {
    const rb = new RequestBuilder(this.rootUrl, AxReferralsV2Service.ReferralsControllerGetCreatedReferralsByContactPath, 'get');
    if (params) {
      rb.path('contactId', params.contactId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxReferral>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `referralsControllerGetCreatedReferralsByContact$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  referralsControllerGetCreatedReferralsByContact(
    params: {
      contactId: number;
    },
    context?: HttpContext
  ): Observable<Array<AxReferral>> {
    return this.referralsControllerGetCreatedReferralsByContact$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxReferral>>): Array<AxReferral> => r.body)
    );
  }

  /** Path part for operation `referralsControllerGetReceivedReferralsByContact()` */
  static readonly ReferralsControllerGetReceivedReferralsByContactPath = '/v2/referrals/receivedReferralsByContact/{contactId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `referralsControllerGetReceivedReferralsByContact()` instead.
   *
   * This method doesn't expect any request body.
   */
  referralsControllerGetReceivedReferralsByContact$Response(
    params: {
      contactId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxReferral>>> {
    const rb = new RequestBuilder(this.rootUrl, AxReferralsV2Service.ReferralsControllerGetReceivedReferralsByContactPath, 'get');
    if (params) {
      rb.path('contactId', params.contactId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxReferral>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `referralsControllerGetReceivedReferralsByContact$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  referralsControllerGetReceivedReferralsByContact(
    params: {
      contactId: number;
    },
    context?: HttpContext
  ): Observable<Array<AxReferral>> {
    return this.referralsControllerGetReceivedReferralsByContact$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxReferral>>): Array<AxReferral> => r.body)
    );
  }

  /** Path part for operation `referralsControllerGetMyReferrals()` */
  static readonly ReferralsControllerGetMyReferralsPath = '/v2/referrals/myReferrals';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `referralsControllerGetMyReferrals()` instead.
   *
   * This method doesn't expect any request body.
   */
  referralsControllerGetMyReferrals$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AxReferralSummaryDto>>> {
    const rb = new RequestBuilder(this.rootUrl, AxReferralsV2Service.ReferralsControllerGetMyReferralsPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AxReferralSummaryDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `referralsControllerGetMyReferrals$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  referralsControllerGetMyReferrals(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<AxReferralSummaryDto>> {
    return this.referralsControllerGetMyReferrals$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AxReferralSummaryDto>>): Array<AxReferralSummaryDto> => r.body)
    );
  }

  /** Path part for operation `referralsControllerCreateReferralCode()` */
  static readonly ReferralsControllerCreateReferralCodePath = '/v2/referrals/createReferralCode';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `referralsControllerCreateReferralCode()` instead.
   *
   * This method doesn't expect any request body.
   */
  referralsControllerCreateReferralCode$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AxCreatedReferralCodeDto>> {
    const rb = new RequestBuilder(this.rootUrl, AxReferralsV2Service.ReferralsControllerCreateReferralCodePath, 'post');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AxCreatedReferralCodeDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `referralsControllerCreateReferralCode$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  referralsControllerCreateReferralCode(
    params?: {
    },
    context?: HttpContext
  ): Observable<AxCreatedReferralCodeDto> {
    return this.referralsControllerCreateReferralCode$Response(params, context).pipe(
      map((r: StrictHttpResponse<AxCreatedReferralCodeDto>): AxCreatedReferralCodeDto => r.body)
    );
  }

}
