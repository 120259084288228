
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { requiredBoolean } from './required-boolean.validator';

export function getWegspesenDetailsFormGroup() {
return new FormGroup({
    date: new FormControl<string | undefined>('', [Validators.required]),
    distanceMax: new FormControl<number | undefined>(undefined, [Validators.required]),
    paidDistanceDifference: new FormControl<number | undefined>(undefined, [Validators.required]),
    isPaid: new FormControl<boolean | undefined>(undefined, [requiredBoolean()]),
})
;
}
      