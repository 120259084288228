
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';


export function getCreateReservationDtoFormGroup() {
return new FormGroup({
    id: new FormControl<number | undefined>(undefined),
    businessunitId: new FormControl<number | undefined>(undefined),
    businessunit: new FormControl<any>(undefined),
    reservationtypeId: new FormControl<number | undefined>(undefined),
    reservationtype: new FormControl<any>(undefined),
    contactId: new FormControl<number | undefined>(undefined),
    contact: new FormControl<any>(undefined),
    start: new FormControl<string | undefined>(undefined),
    end: new FormControl<string | undefined>(undefined),
    company: new FormControl<string | undefined>(undefined),
    titleId: new FormControl<number | undefined>(undefined),
    title: new FormControl<any>(undefined),
    firstName: new FormControl<string | undefined>(undefined),
    lastName: new FormControl<string | undefined>(undefined),
    street: new FormControl<string | undefined>(undefined),
    zipcode: new FormControl<string | undefined>(undefined),
    city: new FormControl<string | undefined>(undefined),
    canton: new FormControl<string | undefined>(undefined),
    objectStreet: new FormControl<string | undefined>(undefined),
    objectZip: new FormControl<string | undefined>(undefined),
    objectCity: new FormControl<string | undefined>(undefined),
    objectCanton: new FormControl<string | undefined>(undefined),
    notes: new FormControl<string | undefined>(undefined),
    email: new FormControl<string | undefined>(undefined),
    phone: new FormControl<string | undefined>(undefined),
    googleCalendarEntry: new FormControl<string | undefined>(undefined),
    accessKey: new FormControl<string | undefined>(undefined),
    created: new FormControl<string | undefined>(undefined),
    modified: new FormControl<string | undefined>(undefined),
    active: new FormControl<boolean | undefined>(undefined),
    otp: new FormControl<string | undefined>(undefined),
    otpVerified: new FormControl<boolean | undefined>(undefined),
    userId: new FormControl<number | undefined>(undefined),
    user: new FormControl<any>(undefined),
    googlecalendarId: new FormControl<number | undefined>(undefined),
    googlecalendar: new FormControl<any>(undefined),
    utm: new FormControl<string | undefined>(undefined),
    referralCode: new FormControl<string | undefined>(undefined),
    apiAppointmentSchedulerKey: new FormControl<string | undefined>(''),
    apiAppointmentSchedulerExternalKey: new FormControl<string | undefined>(''),
})
;
}
      