
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';


export function getSendMailDtoFormGroup() {
return new FormGroup({
    targetUserId: new FormControl<number | undefined>(undefined),
    message: new FormControl<string | undefined>(''),
    subject: new FormControl<string | undefined>(''),
})
;
}
      