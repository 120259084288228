
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';


export function getSendReservationFeedbackDtoFormGroup() {
return new FormGroup({
    googleRecaptchaToken: new FormControl<string | undefined>('', [Validators.required]),
    googleRecaptchaAction: new FormControl<string | undefined>('', [Validators.required]),
    desiredDate: new FormControl<string | undefined>(''),
    contactMail: new FormControl<string | undefined>(''),
    contactPhoneNumber: new FormControl<string | undefined>(''),
    selectedFeedbackOptions: new FormControl<Array<string> | undefined>([]),
    additionalComments: new FormControl<string | undefined>(''),
})
;
}
      