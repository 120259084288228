
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';


export function getSendDocumentsByEmailV2DtoFormGroup() {
return new FormGroup({
    documentIds: new FormControl<Array<number> | undefined>([], [Validators.required]),
    to: new FormControl<Array<string> | undefined>([], [Validators.required]),
    subject: new FormControl<string | undefined>(''),
})
;
}
      