
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { AxWegspesenDetails } from '../models/ax-wegspesen-details';



export function getWegspesenDtoFormGroup() {
return new FormGroup({
    distanceFromHomeToAxova: new FormControl<number | undefined>(undefined, [Validators.required]),
    userId: new FormControl<number | undefined>(undefined, [Validators.required]),
    user: new FormControl<any>(undefined, [Validators.required]),
    kilometeresPaid: new FormControl<number | undefined>(undefined, [Validators.required]),
    kilometeresUnpaid: new FormControl<number | undefined>(undefined, [Validators.required]),
    timerecordIdsPaid: new FormControl<Array<number> | undefined>([], [Validators.required]),
    timerecordIdsUnpaid: new FormControl<Array<number> | undefined>([], [Validators.required]),
    details: new FormControl<AxWegspesenDetails[]>([]),
})
;
}
      